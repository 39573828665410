import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Backdrop,
  Modal,
  Fade,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import Header from "../Helpers/Header";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GetAppIcon from "@material-ui/icons/GetApp";
import HomeIcon from "@material-ui/icons/Home";
import { SERVER_ENDPOINT } from "../../services";
import ShowTestQuestion from "./ShowTestQuestion";
import AppContext from "../App/AppContext";
import CoursesBlockContext from "./CoursesBlockContext";
import PresentationView from "../Presentations/PresentationView";
import { handlingAfterFetch } from "../Helpers/ToolsComponents";
import { fetchAllSlidesOfPresentation } from "../../services/presentations_api";

const style = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100vh",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

function OpenCourse({ courseData, finished }) {
    const emptyTest = { questions: [] };
    const AVAILABLE_CHAPTER_CLASS = "enabled";
    const DISABLED_CHAPTER_CLASS = "disabled";
    const COMPLETE_CHAPTER_CLASS = "completed";
    
    const { loggedInUser,  setAlertObject } = useContext(AppContext);
    const { upsertStudentInCourseState, studentInCourseStates } = useContext(CoursesBlockContext);
    const [allowedToView, setAllowedToView] = useState(true);
    const [open, setOpen] = useState(false);
    const [currentChapter, setCurrentChapter] = useState({});
    const [currentLesson, setCurrentLesson] = useState({});
    const [currentTest, setCurrentTest] = useState(emptyTest);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [chapterAvailability, setChapterAvailability] = useState({});
    const [existingSlides, setExistingSlides] = useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const showCurrentLesson = (chapter, lesson, buttonId) => {
        setCurrentChapter(chapter);
        setCurrentLesson(lesson);
        setCurrentFiles(lesson.files);
        setCurrentTest(emptyTest);
    };

    const prepareStateForUpdate = (chapter, lesson, test, state ) => {
        let state2update = studentInCourseStates.find((s) => {
        return s.chapterId === chapter.id;
        });
        if (!state2update) state2update = state;
        if (!state2update.hasOwnProperty("completed") || !state2update.completed) {
        state2update.completed = {lessons:[], tests:[]}
        }
        if (typeof(state2update.completed) === 'string') state2update.completed = JSON.parse(state2update.completed);
        if(lesson){
        state2update.lessonId = state.lessonId;
        state2update.testId = 0;
        if (!state2update.completed.hasOwnProperty("lessons")){
            state2update.completed.lessons = [state.lessonId]
        }else if (!state2update.completed.lessons.includes(state.lessonId)) {
            state2update.completed.lessons.push(state.lessonId);
        }
        }
        if (test){
        state2update.lessonId = 0;
        state2update.testId = state.testId;
        if (!state2update.completed.hasOwnProperty("tests")){
            state2update.completed.tests = [state.testId]
        }else if (!state2update.completed.tests.includes(state.testId)) {
            state2update.completed.tests.push(state.testId);
        }
        }
        return state2update;
    }

    const addCurentLesson = (chapter, lesson, buttonId, state) => {
        state = prepareStateForUpdate(chapter, lesson, null, state);
        showCurrentLesson(chapter, lesson, buttonId);
        upsertStudentInCourseState(state);
    };

    const showCurrentTest = (chapter, test, buttonId) => {
        setCurrentChapter(chapter);
        setCurrentTest(test);
        setCurrentLesson({});
        setCurrentFiles([]);
    };

    const addCurentTest = (chapter, test, buttonId, state) => {
        state = prepareStateForUpdate(chapter, null, test, state);
        showCurrentTest(chapter, test, buttonId);
        upsertStudentInCourseState(state);
    };

    const updateExpandedChapter = (chapter) => {
        setCurrentChapter(chapter);
    };

    const buildQuestions = () => {
        if (currentTest.questions.length > 0) {
        const questionStates = currentTest.question_states.filter(
            (qs) => qs.studentId === loggedInUser.id
        );
        return currentTest.questions.map((question, key) => {
            let questionState = questionStates.find(
            (qs) => qs.questionId === question.id
            );
            if (!questionState) questionState = {};
            return (
            <ShowTestQuestion
                key={question.id}
                questionData={question}
                questionState={questionState}
                index={key+1}
            />
            );
        });
        }
        return "";
    };

    const getLessonButtonId = (id) => {
        return `itemlesson${id}`;
    };

    const getTestButtonId = (id) => {
        return `itemtest${id}`;
    };

    const createLessons = (chapter) => {
        const items = chapter.lessons.map((item, i) => {
        let item_state = studentInCourseStates.find((s) => {
            return s.chapterId === chapter.id && s.lessonId === item.id;
        });
        if (!item_state) {
            item_state = {
            studentId: loggedInUser.id,
            courseId: courseData.id,
            chapterId: chapter.id,
            testId: 0,
            lessonId: item.id,
            status: "",
            };
        }
        const buttonId = getLessonButtonId(item.id);
        /* add class "passed" if lesson passed or add "current" if you are on lesson */
        let classes = "chapter-content btn-side-content";
        if (currentLesson.id === item.id) classes += " active";
        return (
            <Button
            className={classes}
            id={buttonId}
            onClick={(e) => addCurentLesson(chapter, item, buttonId, item_state)}
            >
            {" "}
            {/* Lesson {i + 1} -  */} {item.lessonName}
            </Button>
        );
        });
        return items;
    };

    const createTests = (chapter) => {
        const items = chapter.tests.map((item, i) => {
        let item_state = studentInCourseStates.find((s) => {
            return s.chapterId === chapter.id && s.testId === item.id;
        });
        if (!item_state) {
            item_state = {
            studentId: loggedInUser.id,
            courseId: courseData.id,
            chapterId: chapter.id,
            testId: item.id,
            lessonId: 0,
            status: "",
            };
        }
        const buttonId = getTestButtonId(item.id);
        let classes = "chapter-content btn-side-content";
        if (currentTest.id === item.id) classes += " active";
        return (
            <Button
            className={classes}
            id={buttonId}
            onClick={(e) => addCurentTest(chapter, item, buttonId, item_state)}
            >
            {" "}
            Test {i + 1} - {item.testName}
            </Button>
        );
        });
        return items;
    };

    const calculateChapterAvailability = () => {
        let available = (courseData.first_not_completed_chapter !== -1);

        const updatedChapterAvailability = {}
        courseData.chapters.forEach((chapter, index) => {
        
        if (finished){
            updatedChapterAvailability[''+chapter.id] = COMPLETE_CHAPTER_CLASS;
            return;
        }
        if (!available) {
            updatedChapterAvailability[''+chapter.id] = DISABLED_CHAPTER_CLASS;
            return;
        }
        updatedChapterAvailability[''+chapter.id] = AVAILABLE_CHAPTER_CLASS;
        if (index === courseData.first_not_completed_chapter){
            available = false;
        }
        })
        setChapterAvailability(updatedChapterAvailability);
        return updatedChapterAvailability;
    }

    //new 2024
    const fetchData = async () => {
        handlingAfterFetch(
            fetchAllSlidesOfPresentation(currentLesson.presentationId), 
            (result) => {
                if (Array.isArray(result.data) && result.data.length > 0){
                    setExistingSlides(result.data);                       
                }
            },
            setAlertObject
        );
    };

    useEffect(() => {
        setExistingSlides([]);
        fetchData(); 
    }, [currentLesson])

    useEffect(() => {
        const updatedChapterAvailability = calculateChapterAvailability();
        const sortedStates = studentInCourseStates.sort((s1,s2) => {
        const lm1 = ''+s1.lastModified; //last modified
        const lm2 = ''+s2.lastModified;
        if (lm1 === lm2 ) return 0;
        if (lm1 > lm2 ) return -1; // > but -1 to get reversed order
        return 1;
        });
        let item_state = null;
        let chapter = courseData.chapters[0];
        for (let i = 0; i < sortedStates.length; i++) {
        const s = sortedStates[i];
        if (updatedChapterAvailability[''+s.chapterId] === AVAILABLE_CHAPTER_CLASS 
            || updatedChapterAvailability[''+s.chapterId] === COMPLETE_CHAPTER_CLASS){
            const chapterOfState = courseData.chapters.find(c => c.id === s.chapterId);
            if (chapterOfState){
            item_state = s;
            chapter = chapterOfState;
            break;
            }
        }      
        }
        if (!item_state) item_state = studentInCourseStates.find((s) => (s.chapterId === chapter.id));
        if (item_state){
        if (item_state.lessonId > 0){
            const lesson = chapter.lessons.find(lesson => lesson.id === item_state.lessonId);
            if (lesson){
            showCurrentLesson(chapter, lesson, getLessonButtonId(lesson.id));
            return;
            }
        }else if (item_state.testId > 0){
            const test = chapter.tests.find(test => test.id === item_state.testId);
            if (test){
            showCurrentTest(chapter, test, getTestButtonId(test.id)); 
            return;  
            }
        }
        }
        if (chapter?.lessons && chapter?.lessons.length > 0) {
        const lesson = chapter.lessons[0];
        showCurrentLesson(chapter, lesson, getLessonButtonId(lesson.id));
        } else if (chapter?.tests && chapter?.tests.length > 0) {
        const test = chapter.tests[0];
        showCurrentTest(chapter, test, getTestButtonId(test.id));      
        }  
    }, [studentInCourseStates]);

    return (
        <>
        <Button
            onClick={handleOpen}
            variant="contained"
            className="primary-brand"
        >
            Open Course
        </Button>
        <div className="modal">
            <Modal
            className="modal-open-course"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            >
            <Fade in={open}>
                <Box sx={style}>
                <>
                    <Header />
                    <div className="side-bar">
                    <h4>{courseData.courseName}</h4>
                    <div className="course-chapters">
                        {courseData.chapters.map((chapter, index) => {
                        return (
                            <Accordion
                            key={chapter.id}
                            className="chapter-data"
                            expanded={currentChapter.id === chapter.id}
                            onChange={(e) => updateExpandedChapter(chapter)}
                            >
                            <AccordionSummary
                                expandIcon={
                                <ArrowDropDownIcon className="secondary" />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={`chapter-name ${currentChapter.id === chapter.id ? "active": ""} ${chapterAvailability[''+chapter.id]}`}
                            >
                                <Typography>
                                {/* Chapter {index + 1} - */} {chapter.chapterName} 
                                </Typography>
                            </AccordionSummary>
                            {createLessons(chapter)}
                            {createTests(chapter)}
                            </Accordion>
                        );
                        })}
                    </div>
                    <Button
                        onClick={handleClose}
                        className="go-back primary-brand"
                    >
                        <HomeIcon /> Back to home
                    </Button>
                    </div>
                    <div className="container">
                    <div
                        className="course-content"
                        style={currentLesson.id ? { display: "block" } : {}}
                        id="lesson-content"
                    >
                        <h2 className="title">
                        {currentLesson && currentLesson.lessonName}
                        </h2>
                        {existingSlides.length > 0 && <PresentationView existingSlides={existingSlides} isCourseDisplay={true}/>}
                        <div
                        className="lesson-content"
                        dangerouslySetInnerHTML={{
                            __html: currentLesson.lessonContent,
                        }}
                        ></div>
                        <div className="lesson-files">
                        {currentFiles.map((file_data, key) => {
                            return (
                            <a
                                className="secondary-bckg light-bg"
                                href={SERVER_ENDPOINT + file_data.fileUrl}
                                download={file_data.fileName}
                                target="_blank"
                            >
                                {" "}
                                <GetAppIcon /> Download Example {key + 1}{" "}
                            </a>
                            );
                        })}

                        </div>
                    </div>
                    <div
                        className="test-content course-content"
                        id="test-content"
                        style={currentTest.id ? { display: "block" } : {}}
                    >
                        <h2 className="title">
                        {currentTest && currentTest.testName}
                        </h2>
                        {currentTest && buildQuestions()}
                    </div>
                    </div>
                </>
                </Box>
            </Fade>
            </Modal>
        </div>
        </>
    );
}
export default OpenCourse;
